export const fieldsForForm = [
    {
        "id": "date",
        "name": "date",
        "label": "Date",
        "heading":"Basic Information",
        "placeholder": "Enter date",
        "type": "date",
        "size": 6
    },
    {
        "id": "state",
        "name": "state",
        "label": "State",
        "placeholder": "Enter state",
        "type": "text",
        "size": 10
    },
    {
        "id": "district",
        "name": "district",
        "label": "District",
        "placeholder": "Enter district",
        "type": "text",
        "size": 10
    },
    {
        "id": "block",
        "name": "block",
        "label": "Block",
        "placeholder": "Enter block",
        "type": "text",
        "size": 10
    },
    {
        "id": "chc_phc",
        "name": "chc_phc",
        "label": "Name of CHC/PHC",
        "placeholder": "Enter name of CHC/PHC",
        "type": "text",
        "size": 10
    },
    {
        "id": "subCenterName",
        "name": "subCenterName",
        "label": "Name of Sub-center",
        "placeholder": "Enter name of sub-center",
        "type": "text",
        "size": 10
    },
    {
        "id": "VHND_AWC",
        "name": "VHND_AWC",
        "label": "Name of VHND site/AWC",
        "placeholder": "Enter name of VHND site/AWC",
        "type": "text",
        "size": 10
    },
    {
        "id": "nameOfFlw",
        "name": "nameOfFlw",
        "label": "Name of FLW",
        "placeholder": "Enter name of FLW",
        "type": "text",
        "size": 10
    },
    {
        "id": "designation",
        "name": "designation",
        "label": "Designation",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "ANM",
                "value": "ANM"
            },
            {
                "label": "ASHA",
                "value": "ASHA"
            },
            {
                "label": "AWW",
                "value": "AWW"
            },
            {
                "label": "Other",
                "value": "other"
            },
        ],
        "subField": [
            {
                "id": "otherDesignation",
                "name": "otherDesignation",
                "label": "If others, please specify",
                "placeholder": "Please specify",
                "type": "text",
                "size": 8
            },
        ]
    },
    {
        "id": "mobileNumber",
        "name": "mobileNumber",
        "label": "Mobile number",
        "placeholder": "Enter mobile number",
        "type": "number",
        "size": 7,
        "minLength": 10,
        "maxLength": 10
    },
    {
        "id": "supervisor_monitor",
        "name": "supervisor_monitor",
        "label": "Name of Supervisor/Monitor",
        "placeholder": "Enter name of Supervisor/Monitor",
        "type": "text",
        "size": 7
    },
    {
        "id": "Organization",
        "name": "Organization",
        "label": "Organization",
        "placeholder": "Enter name of organization",
        "type": "text",
        "size": 7
    },
    {
        "id": "supervisorDesignation",
        "name": "supervisorDesignation",
        "label": "Designation",
        "placeholder": "Designation",
        "type": "text",
        "size": 7
    },
    {
        "id": "supervisorMobile",
        "name": "supervisorMobile",
        "label": "Mobile No.",
        "placeholder": "Enter Mobile no",
        "type": "number",
        "size": 7
    },
    {
        "id": "trainingAMB",
        "name": "trainingAMB",
        "heading": "1. Thematic Area - Training",
        "label": "Have you received any training on AMB in last three years?",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            },
        ]
    },
    {
        "id": "totalPopulation",
        "name": "totalPopulation",
        "heading": "2. Thematic Area - Target Population",
        "label": "2.1 Total population catered (count)",
        "placeholder": "Enter total population",
        "type": "number",
        "size": 7,
    },
    {
        "id": "reproductiveAge",
        "name": "reproductiveAge",
        "heading": "2.2 Target population in 6 intervention groups",
        "label": "2.2.1 Women in Reproductive Age (count)",
        "placeholder": "Enter total population",
        "type": "number",
        "size": 7,
    },
    {
        "id": "totalPregnanatWomen",
        "name": "totalPregnanatWomen",
        "label": "2.2.2 Pregnant women (count)",
        "placeholder": "Enter total Pregnant women",
        "type": "number",
        "size": 7,
    },
    {
        "id": "totalLactatingWomen",
        "name": "totalLactatingWomen",
        "label": "2.2.3 Lactating women (count)",
        "placeholder": "Enter total Lactating women",
        "type": "number",
        "size": 7,
    },
    {
        "id": "totalChildrenMonth",
        "name": "totalChildrenMonth",
        "label": "2.2.4 Children (6-59 months)",
        "placeholder": "Enter Children months",
        "type": "number",
        "size": 7,
    },
    {
        "id": "totalChildrenYear",
        "name": "totalChildrenYear",
        "label": "2.2.5 Children (5-9 years) out of school",
        "placeholder": "Enter Children year",
        "type": "number",
        "size": 7,
    },
    {
        "id": "totalAdolescentsYear",
        "name": "totalAdolescentsYear",
        "label": "2.2.6 Adolescents (10-19 years) OOS",
        "placeholder": "Enter Adolescents year",
        "type": "number",
        "size": 7,
    },
    {
        "id": "testScreenForAnemia",
        "name": "testScreenForAnemia",
        "heading": "3. Thematic Area - Screening/ Testing",
        "label": "3.1 Which method is used to test/screen for anemia?",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Digital Hemoglobinometer",
                "value": "digitalHemoglobinometer"
            },
            {
                "label": "Color coded card",
                "value": "colorCodedCard"
            },
            {
                "label": "Sahli's method",
                "value": "sahli'sMethod"
            },
            {
                "label": "Other",
                "value": "other"
            },
        ],
        "subField": [
            {
                "id": "othertestScreenForAnemia",
                "name": "othertestScreenForAnemia",
                "label": "If others, please specify",
                "placeholder": "Please specify",
                "type": "text",
                "size": 8
            },
        ]
    },
    {
        "id": "digitalHemoglobinometers",
        "name": "digitalHemoglobinometers",
        "heading": "3.2 Do you have a functional digital hemoglobinometer with adequate consumables for the screening of beneficiaries of AMB?",
        "label": "3.2.1 Digital hemoglobinometers",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            },
        ]
    },
    {
        "id": "consumables",
        "name": "consumables",
        "label": "3.2.2 Consumables",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            },
        ]
    },
    {
        "id": "reproductiveAge4",
        "name": "reproductiveAge4",
        "heading": "4. Thematic Area - Prophylaxis/ Treatment",
        "subHeadig": "4.1 Number of beneficiaries provided IFA in last completed month",
        "label": "4.1.1 Women in Reproductive Age (count)",
        "placeholder": "Enter Reproductive Age",
        "type": "number",
        "size": 7,
    },
    {
        "id": "pregnantWomen4",
        "name": "pregnantWomen4",
        "label": "4.1.2 Pregnant women (count)",
        "placeholder": "Enter",
        "type": "number",
        "size": 7,
    },
    {
        "id": "totalLactatingWomen4",
        "name": "totalLactatingWomen4",
        "label": "4.1.3 Lactating women (count)",
        "placeholder": "Enter total Lactating women",
        "type": "number",
        "size": 7,
    },
    {
        "id": "totalChildrenMonth4",
        "name": "totalChildrenMonth4",
        "label": "4.1.4 Children (6-59 months)",
        "placeholder": "Enter Children months",
        "type": "number",
        "size": 7,
    },
    {
        "id": "totalChildrenYear4",
        "name": "totalChildrenYear4",
        "label": "4.1.5 Children (5-9 years)",
        "placeholder": "Enter Children year",
        "type": "number",
        "size": 7,
    },
    {
        "id": "totalAdolescentsYear4",
        "name": "totalAdolescentsYear4",
        "label": "4.1.6 Adolescents (10-19 years)",
        "placeholder": "Enter Adolescents year",
        "type": "number",
        "size": 7,
    },
    {
        "id": "provideAlbendazole",
        "name": "provideAlbendazole",
        "label": "4.2 Which of these beneficiaries do you provide Albendazole?",
        "type": "checkbox",
        "size": 12,
        "options": [
            {
                "label": "Out of school children (5-9 years)",
                "value": "Out of school children (5-9 years)"
            },
            {
                "label": "Out of school adolescents (10-19 years)",
                "value": "Out of school adolescents (10-19 years)"
            },
            {
                "label": "Women in Reproductive age",
                "value": "Women in Reproductive age"
            },
            {
                "label": "Pregnant Women",
                "value": "Pregnant Women"
            },
        ]
    },
    {
        "id": "currentlyRegister",
        "name": "currentlyRegister",
        "heading": "4.3 Status of Pregnant Women",
        "label": "4.3.1 Currently registered (count)",
        "type": "number",
        "placeholder": 'Enter currently registered',
        "size": 7,
    },
    {
        "id": "screenedAnemic",
        "name": "screenedAnemic",
        "label": "4.3.2 Screened anemic (count)",
        "type": "number",
        "placeholder": 'Enter screened anemic',
        "size": 7,
    },
    {
        "id": "onTreatment",
        "name": "onTreatment",
        "label": "4.3.3 On treatment (count)",
        "type": "number",
        "placeholder": 'Enter On treatment',
        "size": 7,
    },
    {
        "id": "anemicReferred",
        "name": "anemicReferred",
        "label": "4.3.4 Severely anemic referred (count)",
        "type": "number",
        "placeholder": 'Enter anemic referred',
        "size": 7,
    },
    {
        "id": "anemicPW",
        "name": "anemicPW",
        "label": "4.4 Does ANM has line listing of severe anemic PW? (observe)",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            },
        ]
    },
    {
        "id": "VHND_AWC",
        "name": "VHND_AWC",
        "heading": "5. Thematic Area - Communication",
        "label": "5.1 Whether any IEC material is displayed at the VHND/AWC?",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            },
        ]
    },
    {
        "id": "IEC",
        "name": "IEC",
        "label": "5.2 IEC methods used?",
        "type": "checkbox",
        "size": 12,
        "options": [
            {
                "label": "Printed IEC material (posters/pamphlets)",
                "value": "Printed IEC material (posters/pamphlets)"
            },
            {
                "label": "Outdoor display IEC (banner/poster/hoarding/wall painting",
                "value": "Outdoor display IEC (banner/poster/hoarding/wall painting"
            },
            {
                "label": "Mass awareness (campaigns/street shows)",
                "value": "Mass awareness (campaigns/street shows)"
            },
            {
                "label": "Others",
                "value": "Others"
            },
        ]
    },
    {
        "id": "ANCSession",
        "name": "ANCSession",
        "label": "5.3 Did the FLW give advice on key messages to beneficiary? Observe an ANC session (IFA supplementation, deworming, diet)",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            },
            {
                "label": "NA",
                "value": "na"
            },
        ]
    },
    {
        "id": "T3Camp",
        "name": "T3Camp",
        "label": "5.4 Was any T3 camp organized in last three months in your catchment area?",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            },
            {
                "label": "NA",
                "value": "na"
            },
        ]
    },
    {
        "id": "AlbendazoleReceive",
        "name": "AlbendazoleReceive",
        "heading": '6. Thematic Area - Stock Management',
        "label": "6.1 From where do you receive/collect the IFA and Albendazole?",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "PHC",
                "value": "phc"
            },
            {
                "label": "CHC",
                "value": "chc"
            },
            {
                "label": "District Hospital",
                "value": "districtHospital"
            },
            {
                "label": "Block Store",
                "value": "blockStore"
            },
            {
                "label": "District Store",
                "value": "districtStore"
            },
            {
                "label": "Others",
                "value": "others"
            },
        ],
        "subField": [
            {
                "id": "otherAlbendazoleReceive",
                "name": "otherAlbendazoleReceive",
                "label": "If others, please specify",
                "placeholder": "Please specify",
                "type": "text",
                "size": 8
            },
        ]
    },
    {
        "id": "IFA",
        "name": "IFA",
        "heading": '6.2 Frequency of receipt of',
        "label": "6.2.1 IFA",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Monthly",
                "value": "monthly"
            },
            {
                "label": "Quarterly",
                "value": "quarterly"
            },
            {
                "label": "Biannually",
                "value": "biannually"
            },
            {
                "label": "Annually",
                "value": "annually"
            },
            {
                "label": "Half Annually",
                "value": "halfAnnually"
            },
            {
                "label": "Not Fixed",
                "value": "notFixed"
            },
        ]
    },
    {
        "id": "albendazole",
        "name": "albendazole",
        "label": "6.2.2 Albendazole",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Monthly",
                "value": "monthly"
            },
            {
                "label": "Quarterly",
                "value": "quarterly"
            },
            {
                "label": "Biannually",
                "value": "biannually"
            },
            {
                "label": "Annually",
                "value": "annually"
            },
            {
                "label": "Half Annually",
                "value": "halfAnnually"
            },
            {
                "label": "Not Fixed",
                "value": "notFixed"
            },
        ]
    },
    {
        "id": "last3Months",
        "name": "last3Months",
        "heading": '6.3 Whether any stock-out of IFA occurred?',
        "label": "6.3.1 In last 3 months",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            },
        ],
        "IFAtype": {
            "id": "stockOutIfa3MonthsList",
            "name": "stockOutIfa3MonthsList",
            "label": "If yes, select type",
            "type": "checkbox",
            "options": [
                {
                    "label": "IFA Red",
                    "value": "ifaRed"
                },
                {
                    "label": "IFA Blue",
                    "value": "ifaBlue"
                },
                {
                    "label": "IFA Pink",
                    "value": "ifaPink"
                },
                {
                    "label": "Iron Syrup",
                    "value": "ironSyrup"
                },
                {
                    "label": "FCM",
                    "value": "fcm"
                },
                {
                    "label": "Iron Sucrose",
                    "value": "ironSucrose"
                }
            ]
        }
    },
    {
        "id": "last6Months",
        "name": "last6Months",
        "label": "6.3.2 In last 6 months",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            },
        ],
        "IFAtype": {
            "id": "stockOutIfa6MonthsList",
            "name": "stockOutIfa6MonthsList",
            "label": "If yes, select type",
            "type": "checkbox",
            "options": [
                {
                    "label": "IFA Red",
                    "value": "ifaRed"
                },
                {
                    "label": "IFA Blue",
                    "value": "ifaBlue"
                },
                {
                    "label": "IFA Pink",
                    "value": "ifaPink"
                },
                {
                    "label": "Iron Syrup",
                    "value": "ironSyrup"
                },
                {
                    "label": "FCM",
                    "value": "fcm"
                },
                {
                    "label": "Iron Sucrose",
                    "value": "ironSucrose"
                }
            ]
        }
    },
    {
        "id": "next3Months",
        "name": "next3Months",
        "heading": '6.4 Is there enough stock of IFA?',
        "label": "6.4.1 Next 3 months",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            },
        ],
        "IFAtype": {
            "id": "enoughStockIfa3MonthsList",
            "name": "enoughStockIfa3MonthsList",
            "label": "If yes, select type",
            "type": "checkbox",
            "options": [
                {
                    "label": "IFA Red",
                    "value": "ifaRed"
                },
                {
                    "label": "IFA Blue",
                    "value": "ifaBlue"
                },
                {
                    "label": "IFA Pink",
                    "value": "ifaPink"
                },
                {
                    "label": "Iron Syrup",
                    "value": "ironSyrup"
                },
                {
                    "label": "FCM",
                    "value": "fcm"
                },
                {
                    "label": "Iron Sucrose",
                    "value": "ironSucrose"
                }
            ]
        }
    },
    {
        "id": "next6Months",
        "name": "next6Months",
        "label": "6.4.2 Next 6 months",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            },
        ],
        "IFAtype": {
            "id": "enoughStockIfa6MonthsList",
            "name": "enoughStockIfa6MonthsList",
            "label": "If yes, select type",
            "type": "checkbox",
            "options": [
                {
                    "label": "IFA Red",
                    "value": "ifaRed"
                },
                {
                    "label": "IFA Blue",
                    "value": "ifaBlue"
                },
                {
                    "label": "IFA Pink",
                    "value": "ifaPink"
                },
                {
                    "label": "Iron Syrup",
                    "value": "ironSyrup"
                },
                {
                    "label": "FCM",
                    "value": "fcm"
                },
                {
                    "label": "Iron Sucrose",
                    "value": "ironSucrose"
                }
            ]
        }
    },
    {
        "id": "expiryDate",
        "name": "expiryDate",
        "label": "6.5 Is the available IFA within expiry date? (Observe)",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            },
            {
                "label": "NA",
                "value": "na"
            },
        ]
    },
    {
        "id": "HMISFormat",
        "name": "HMISFormat",
        "heading": '7. Thematic Area - Data Recording & Reporting',
        "label": "7.1 Have you received the new HMIS formats and training been given?",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Both Yes",
                "value": "Both Yes"
            },
            {
                "label": "Received - Yes, Trained - No",
                "value": "Received - Yes, Trained - No"
            },
            {
                "label": "Received - No, Trained - Yes",
                "value": "Received - No, Trained - Yes"
            },
            {
                "label": "Both No",
                "value": "Both No"
            },
        ]
    },
    {
        "id": "AMBReport",
        "name": "AMBReport",
        "label": "7.2 Have you submitted the AMB report at your health facility, every month in the last quarter?",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": " No",
                "value": "false"
            },
        ],
        "subField": [
            {
                "id": "submittedAmbReportReason",
                "name": "submittedAmbReportReason",
                "label": "If no, please state the reason",
                "placeholder": "Please specify",
                "type": "text",
                "size": 8
            },
        ]
    },
    {
        "id": "reproductiveAge8",
        "name": "reproductiveAge8",
        "label": "8.4.1 Women in Reproductive Age",
        "placeholder": "Enter total population",
        "type": "text",
        "size": 10,
    },
    {
        "id": "totalPregnanatWomen8",
        "name": "totalPregnanatWomen8",
        "label": "8.4.2 Pregnant women",
        "placeholder": "Enter total Pregnant women",
        "type": "text",
        "size": 10,
    },
    {
        "id": "totalLactatingWomen8",
        "name": "totalLactatingWomen8",
        "label": "8.4.3 Lactating women",
        "placeholder": "Enter total Lactating women",
        "type": "text",
        "size": 10,
    },
    {
        "id": "totalChildrenMonth8",
        "name": "totalChildrenMonth8",
        "label": "8.4.4 Children (6-59 months)",
        "placeholder": "Enter Children months",
        "type": "text",
        "size": 10,
    },
    {
        "id": "dosageChild59",
        "name": "dosageChild59",
        "label": "8.4.5 Children (5-9 years)",
        "placeholder": "Enter Children year",
        "type": "text",
        "size": 10,
    },
    {
        "id": "totalAdolescentsYear8",
        "name": "totalAdolescentsYear8",
        "label": "8.4.6 Adolescents (10-19 years)",
        "placeholder": "Enter Adolescents year",
        "type": "text",
        "size": 10,
    },

];
