export const fieldsForForm = [
    {
        "id": "date",
        "name": "date",
        "label": "Date",
        "heading": "Basic Information",
        "placeholder": "Enter date",
        "requird": true,
        "type": "date",
        "size": 6
    },
    {
        "id": "state",
        "name": "state",
        "label": "State",
        "placeholder": "Enter state",
        "requird": true,
        "type": "text",
        "size": 10
    },
    {
        "id": "officerName",
        "name": "officerName",
        "label": "Name of Officer",
        "placeholder": "Enter name",
        "requird": true,
        "type": "text",
        "size": 10
    },
    {
        "id": "designation",
        "name": "designation",
        "label": "Designation",
        "placeholder": "Enter designation",
        "requird": true,
        "type": "text",
        "size": 10
    },
    {
        "id": "mobileNumber",
        "name": "mobileNumber",
        "label": "Mobile number",
        "placeholder": "Enter mobile number",
        "requird": true,
        "type": "number",
        "size": 7,
        "minLength": 10,
        "maxLength": 10
    },
    {
        "id": "supervisorName",
        "name": "supervisorName",
        "label": "Name of Supervisor/Monitor",
        "placeholder": "Enter name of Supervisor/Monitor",
        "requird": true,
        "type": "text",
        "size": 7
    },
    {
        "id": "organisation",
        "name": "organisation",
        "label": "Name of Organisation",
        "placeholder": "Enter name of organization",
        "requird": true,
        "type": "text",
        "size": 7
    },
    {
        "id": "supervisorDesignation",
        "name": "supervisorDesignation",
        "label": "Name of Designation",
        "placeholder": "Designation",
        "requird": true,
        "type": "text",
        "size": 7
    },
    {
        "id": "ambName",
        "name": "ambName",
        "label": "AMB Name",
        "placeholder": "Enter name",
        "requird": true,
        "type": "number",
        "size": 7
    },
    {
        "id": "ambDesignation",
        "name": "ambDesignation",
        "label": "AMB Designation",
        "placeholder": "Enter designation",
        "requird": true,
        "type": "number",
        "size": 7
    },
    {
        "id": "ambEmail",
        "name": "ambEmail",
        "label": "AMB Email",
        "placeholder": "Enter email",
        "requird": true,
        "type": "number",
        "size": 7
    },
    {
        "id": "ambMobileNumber",
        "name": "ambMobileNumber",
        "label": "Mobile No.",
        "placeholder": "Enter Mobile no",
        "requird": true,
        "type": "number",
        "size": 7
    },
    {
        "id": "isNodelAmb",
        "name": "isNodelAmb",
        "heading": "1. Thematic Area - Training",
        "label": "1.1 Is Nodal officer for AMB designated at State level?",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            },
        ]
    },
    {
        "id": "receivedTraining",
        "name": "receivedTraining",
        "label": "1.2 Have you received any training on AMB in last three years?",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            },
        ]
    },
    {
        "id": "trainingStateOfficer",
        "name": "trainingStateOfficer",
        "heading": "1.3 Status of AMB training and orientation for Programme Officers and Healthcare workers (for Health, WCD and Education) in last 3 years?",
        "label": "1.3.1 State Officers",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Training Conducted",
                "value": "Training Conducted"
            },
            {
                "label": "No trainings held",
                "value": "No trainings held"
            },
            {
                "label": "NA",
                "value": "N/A"
            },
        ]
    },
    {
        "id": "trainingDistrictOfficer",
        "name": "trainingDistrictOfficer",
        "label": "1.3.2 District Officer",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Training Conducted",
                "value": "Training Conducted"
            },
            {
                "label": "No trainings held",
                "value": "No trainings held"
            },
            {
                "label": "NA",
                "value": "N/A"
            },
        ]
    },
    {
        "id": "trainingCDPO",
        "name": "trainingCDPO",
        "label": "1.3.3 CDPO Officers",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Training Conducted",
                "value": "Training Conducted"
            },
            {
                "label": "No trainings held",
                "value": "No trainings held"
            },
            {
                "label": "NA",
                "value": "N/A"
            },
        ]
    },
    {
        "id": "trainingDEO",
        "name": "trainingDEO",
        "label": "1.3.4 Education – BEOs",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Training Conducted",
                "value": "Training Conducted"
            },
            {
                "label": "No trainings held",
                "value": "No trainings held"
            },
            {
                "label": "NA",
                "value": "N/A"
            },
        ]
    },
    {
        "id": "trainingANM",
        "name": "trainingANM",
        "heading": "1.4 Status of AMB training and orientation for the frontline workers (for Health, WCD and Education) in last three years",
        "label": "1.4.1 ANM",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Training Conducted",
                "value": "Training Conducted"
            },
            {
                "label": "No trainings held",
                "value": "No trainings held"
            },
            {
                "label": "NA",
                "value": "N/A"
            },
        ]
    },
    {
        "id": "trainingASHA",
        "name": "trainingASHA",
        "label": "1.4.2 ASHA",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Training Conducted",
                "value": "Training Conducted"
            },
            {
                "label": "No trainings held",
                "value": "No trainings held"
            },
            {
                "label": "NA",
                "value": "N/A"
            },
        ]
    },
    {
        "id": "trainingAWW",
        "name": "trainingAWW",
        "label": "1.4.3 AWW",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Training Conducted",
                "value": "Training Conducted"
            },
            {
                "label": "No trainings held",
                "value": "No trainings held"
            },
            {
                "label": "NA",
                "value": "N/A"
            },
        ]
    },
    {
        "id": "trainingRBSK",
        "name": "trainingRBSK",
        "label": "1.4.4 School Teacher and RBSK teams",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Training Conducted",
                "value": "Training Conducted"
            },
            {
                "label": "No trainings held",
                "value": "No trainings held"
            },
            {
                "label": "NA",
                "value": "N/A"
            },
        ]
    },
    {
        "id": "trainingCHO",
        "name": "trainingCHO",
        "label": "1.4.5 CHOs",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Training Conducted",
                "value": "Training Conducted"
            },
            {
                "label": "No trainings held",
                "value": "No trainings held"
            },
            {
                "label": "NA",
                "value": "N/A"
            },
        ]
    },
    {
        "id": "jointReviewMeeting",
        "name": "jointReviewMeeting",
        "heading": "2. Thematic Area - Governance and Coordination",
        "subHeading": "2.1 What is the mechanism of inter-departmental coordination with Health, WCD and Education departments?",
        "label": "2.1.1 Joint Review Meeting at State level",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "lastMeetingDate",
        "name": "lastMeetingDate",
        "label": "Last Meeting Date",
        "placeholder": "Select date",
        "type": "date",
        "size": 6
    },
    {
        "id": "jointLetterInst",
        "name": "jointLetterInst",
        "label": "2.1.2 Joint letter of instructions to blocks and below for programme implementation",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "lastJointLetterDate",
        "name": "lastJointLetterDate",
        "label": "Last Joint letter Date",
        "placeholder": "Select date",
        "type": "date",
        "size": 6
    },
    {
        "id": "jointFieldVisit",
        "name": "jointFieldVisit",
        "label": "2.1.3 Joint field visits",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "jointReviewMeetingState",
        "name": "jointReviewMeetingState",
        "heading": "2.2 What is the mechanism of intra-departmental coordination for fluorosis, malaria and hemoglobinopathies departments?",
        "label": "2.2.1 Joint Review Meetings at State level",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "lastMeetingStateDate",
        "name": "lastMeetingStateDate",
        "label": "Last Joint Review Meeting Date",
        "placeholder": "Select date",
        "type": "date",
        "size": 6
    },
    {
        "id": "jointLetterInstState",
        "name": "jointLetterInstState",
        "label": "2.2.2 Joint letter of instructions to blocks and below for programme implementation",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "lastJointLetterStateDate",
        "name": "lastJointLetterStateDate",
        "label": "Last Joint Letter Start Date",
        "placeholder": "Select date",
        "type": "date",
        "size": 6
    },
    {
        "id": "jointFieldVisitState",
        "name": "jointFieldVisitState",
        "label": "2.2.3 Joint field visits",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "ifaRedWomen",
        "name": "ifaRedWomen",
        "heading": "3. Thematic Area - Stock Procurement & Supply Chain Management",
        "subHeading": "3.1 Which of these are received in current year by the district?",
        "label": "3.1.1 IFA Red for Women in reproductive age",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "ifaRedPregnant",
        "name": "ifaRedPregnant",
        "label": "3.1.2 IFA Red for pregnant & lactating women",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "ifaPink",
        "name": "ifaPink",
        "label": "3.1.3 IFA Pink for 5-10 years children",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "ifaBlue",
        "name": "ifaBlue",
        "label": "3.1.4 IFA Blue for 10-19 years adolescents",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "ifaSyrup",
        "name": "ifaSyrup",
        "label": "3.1.5 IFA Syrup with dropper for 6-59 months children",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "fcm",
        "name": "fcm",
        "label": "3.1.6 Ferric Carboxymaltose (FCM)",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "sucrose",
        "name": "sucrose",
        "label": "3.1.7 IV Sucrose",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "hemoglobionometer",
        "name": "hemoglobionometer",
        "label": "3.1.8 Digital Hemoglobinometer",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "albendazolePregnant",
        "name": "albendazolePregnant",
        "label": "3.1.9 Albendazole for pregnant women",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "albendazoleChildren",
        "name": "albendazoleChildren",
        "label": "3.1.10 Albendazole for children",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "ifaProcurement",
        "name": "ifaProcurement",
        "label": "3.2 At what level the procurement of IFA is done?",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "ifaProcurementStatus",
        "name": "ifaProcurementStatus",
        "label": "3.3 What is the status of procurement of IFA so for current FY?",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Completed",
                "value": "Completed"
            },
            {
                "label": "Partially Completed",
                "value": "Partially Completed"
            },
            {
                "label": "Centrally Procured",
                "value": "Centrally Procured"
            },
            {
                "label": "Information Not Available",
                "value": "Information Not Available"
            }
        ]
    },
    {
        "id": "fcmStatus",
        "name": "fcmStatus",
        "heading": "3.4 What is the status of procurement of injectables and digital hemoglobinometers for current FY?",
        "label": "3.4.1 Ferric Carboxymaltose (FCM)",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Completed",
                "value": "Completed"
            },
            {
                "label": "Partially Completed",
                "value": "Partially Completed"
            },
            {
                "label": "Information Not Available",
                "value": "Information Not Available"
            }
        ]
    },
    {
        "id": "sucroseStatus",
        "name": "sucroseStatus",
        "label": "3.4.2 IV Sucrose",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Completed",
                "value": "Completed"
            },
            {
                "label": "Partially Completed",
                "value": "Partially Completed"
            },
            {
                "label": "Information Not Available",
                "value": "Information Not Available"
            }
        ]
    },
    {
        "id": "hemoglobionometerStatus",
        "name": "hemoglobionometerStatus",
        "label": "3.4.3 Digital Hemoglobinometer",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Completed",
                "value": "Completed"
            },
            {
                "label": "Partially Completed",
                "value": "Partially Completed"
            },
            {
                "label": "Information Not Available",
                "value": "Information Not Available"
            }
        ]
    },
    {
        "id": "ifaRedWomenDistributed",
        "name": "ifaRedWomenDistributed",
        "heading": "3.5 Which of these are distributed to the districts in current year?",
        "label": "3.5.1 IFA Red for Women in reproductive age",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "ifaRedPregnantDistributed",
        "name": "ifaRedPregnantDistributed",
        "label": "3.5.2 IFA Red for pregnant & lactating women",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "ifaPinkDistributed",
        "name": "ifaPinkDistributed",
        "label": "3.5.3 IFA Pink for 5-10 years children",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "ifaBlueDistributed",
        "name": "ifaBlueDistributed",
        "label": "3.5.4 IFA Blue for 10-19 years adolescents",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "ifaSyrupDistributed",
        "name": "ifaSyrupDistributed",
        "label": "3.5.5 IFA Syrup with dropper for 6-59 months children",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "fcmDistributed",
        "name": "fcmDistributed",
        "label": "3.5.6 Ferric Carboxymaltose (FCM)",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "sucroseDistributed",
        "name": "sucroseDistributed",
        "label": "3.5.7 IV Sucrose",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "hemoglobionometerDistributed",
        "name": "hemoglobionometerDistributed",
        "label": "3.5.8 Digital Hemoglobinometer",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "stockoutStatusLast3Months",
        "name": "stockoutStatusLast3Months",
        "heading": "3.6 Whether any stock-out of IFA occurred?",
        "label": "3.6.1 In last 3 months",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "stockoutStatusLast6Months",
        "name": "stockoutStatusLast6Months",
        "label": "3.6.2 In last 6 months",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "stockoutStatusNext3Months",
        "name": "stockoutStatusNext3Months",
        "heading": "3.7 Is there enough stock of IFA?",
        "label": "3.7.1 Next 3 months",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "stockoutStatusNext6Months",
        "name": "stockoutStatusNext6Months",
        "label": "3.7.2 Next 6 months",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "ifaWithinExpiryStatus",
        "name": "ifaWithinExpiryStatus",
        "label": "3.8 Is the available IFA within expiry date?",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "helcdometerAvailability",
        "name": "helcdometerAvailability",
        "label": "3.9 Are Digital hemoglobinometer available with all ANMs and RBSK teams?",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "socialMediaPlan",
        "name": "socialMediaPlan",
        "heading": "4. Thematic Area - Communication and IEC",
        "subHeading": "4.1 State/District wise outdoor media plan (wall writing, bus panels, hoardings) available",
        "label": "4.1.2 Community Media Plan (community radio station, folk groups)",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "socialMediaPlan",
        "name": "socialMediaPlan",
        "label": "4.1.3 Social Media Plan (electronic)",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "printing",
        "name": "printing",
        "heading": "4.2 Has any IEC material been printed at the district level and distribution completed in the three years?",
        "label": "4.2.1 Printed",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "distributed",
        "name": "distributed",
        "label": "4.2.2 Distributed",
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "ifaRedWomenCoverage",
        "name": "ifaRedWomenCoverage",
        "heading": "5. Thematic Area - Data Recording & Reporting",
        "subHeading": "5.1 KPI of last completed quarter for AMB?",
        "label": "5.1.1 IFA Red for Women in reproductive age (Coverage in %)",
        "placeholder": "",
        "requird": true,
        "type": "number",
        "size": 7,
    },
    {
        "id": "ifaRedPregnantCoverage",
        "name": "ifaRedPregnantCoverage",
        "label": "5.1.2 IFA Red for pregnant (Coverage in %)",
        "placeholder": "",
        "requird": true,
        "type": "number",
        "size": 7,
    },
    {
        "id": "ifaRedLactatingCoverage",
        "name": "ifaRedLactatingCoverage",
        "label": "5.1.3 IFA Red for lactating women (Coverage in %)",
        "placeholder": "",
        "requird": true,
        "type": "number",
        "size": 7,
    },
    {
        "id": "ifaPinkCoverage",
        "name": "ifaPinkCoverage",
        "label": "5.1.4 IFA Pink for 5-10 years children (Coverage in %)",
        "placeholder": "",
        "requird": true,
        "type": "number",
        "size": 7,
    },
    {
        "id": "ifaBlueCoverage",
        "name": "ifaBlueCoverage",
        "label": "5.1.5 IFA Blue for 10-19 years adolescents (Coverage in %)",
        "placeholder": "",
        "requird": true,
        "type": "number",
        "size": 7,
    },
    {
        "id": "ifaSyrupCoverage",
        "name": "ifaSyrupCoverage",
        "label": "5.1.6 IFA Syrup with dropper for 6-59 months children (Coverage in %)",
        "placeholder": "",
        "requird": true,
        "type": "number",
        "size": 7,
    },
    {
        "id": "fcmCoverage",
        "name": "fcmCoverage",
        "label": "5.1.7 Ferric Carboxymaltose (FCM) (Coverage in %)",
        "placeholder": "",
        "requird": true,
        "type": "number",
        "size": 7,
    },
    {
        "id": "sucroseCoverage",
        "name": "sucroseCoverage",
        "label": "5.1.8 IV Sucrose (Coverage in %)",
        "placeholder": "",
        "requird": true,
        "type": "number",
        "size": 7,
    },
    {
        "id": "isTrainingNewAmbFormat",
        "name": "isTrainingNewAmbFormat",
        "label": "5.2 Are the trainings on new AMB HMIS format completed?",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "ambHmisFormatAvailability",
        "name": "ambHmisFormatAvailability",
        "label": "5.3 Are the new AMB HMIS formats available?",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "ambT3CountPlanned",
        "name": "ambT3CountPlanned",
        "heading": "6. Thematic Area - Programme Implementation and Monitoring",
        "subHeading": "6.1 AMB T3 camps organized vs planned during current financial year",
        "label": "6.1.1 Count of AMB T3 camps planned",
        "placeholder": "",
        "requird": true,
        "type": "number",
        "size": 12,
    },
    {
        "id": "ambT3CountOrganised",
        "name": "ambT3CountOrganised",
        "label": "6.1.2 Count of AMB T3 camps organized",
        "placeholder": "",
        "requird": true,
        "type": "number",
        "size": 7,
    },
    {
        "id": "monitorProgramme",
        "name": "monitorProgramme",
        "label": "6.2 How do you monitor the programme activities?",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Review_Meetings",
                "value": "Review_Meetings"
            },
            {
                "label": "Field monitoring visits",
                "value": "Field monitoring visits"
            },
            {
                "label": "Data of reporting portals",
                "value": "Data of reporting portals"
            },
            {
                "label": "Others",
                "value": "Others"
            }
        ]
    },
    {
        "id": "technicalSupportPartneravalability",
        "name": "technicalSupportPartneravalability",
        "label": "6.3 Is there any technical support partner for AMB Programme in the state?",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "technicalSupportPartner",
        "name": "technicalSupportPartner",
        "label": "6.3.1 Technical Support Partner Name",
        "placeholder": "Enter Name",
        "type": "text",
        "size": 12,
    },
    {
        "id": "publicDistributionSystem",
        "name": "publicDistributionSystem",
        "heading": "6.4 Has state rolled out the mandatory iron- fortification of foods in government funded programmes?",
        "label": "6.4.1 Public Distribution System",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "midDayMeal",
        "name": "midDayMeal",
        "label": "6.4.2 Mid-day Meal Programme",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "icds",
        "name": "icds",
        "label": "6.4.3 ICDS",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
    {
        "id": "isScreeningConducted",
        "name": "isScreeningConducted",
        "label": "6.5 Does the screening and treatment of non-nutritional causes (Fluorosis/Malaria) of anemia in endemic pockets been conducted in your state?",
        "requird": true,
        "type": "radio",
        "size": 12,
        "options": [
            {
                "label": "Yes",
                "value": "true"
            },
            {
                "label": "No",
                "value": "false"
            }
        ]
    },
];
