import { Download } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { CSVLink } from "react-csv";

const DenominatorsTable = ({
  tableData,
  loading,
  columnDefs,
  selectedLocation,
  selectedYear,
}) => {
  function CustomToolbar() {
    const colDefs = columnDefs.map((data) => ({
      label: data.headerName,
      key: data.field,
    }));

    return (
      <div className="d-flex align-items-center justify-content-between p-2">
        <small>*figures in lakhs</small>

        {tableData.length > 0 && (
          <CSVLink
            data={tableData}
            headers={colDefs}
            className="btn text-white my-2"
            style={{ backgroundColor: "#105bab" }}
            filename={`Denominator_${selectedLocation}_${selectedYear}`}
          >
            <Download /> Download Data
          </CSVLink>
        )}
      </div>
    );
  }

  return (
    <DataGrid
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      getRowId={(row) => row.location}
      sx={{
        width: "100%",
        "& .table-header": {
          height: "max-content",
          backgroundColor: "#069edb",
          color: "white",
        },
      }}
      pagination={tableData.length}
      pageSizeOptions={[10, 25, 50]}
      disableRowSelectionOnClick
      loading={loading}
      rows={tableData}
      columns={columnDefs}
      pageSize={10}
      slots={{
        toolbar: CustomToolbar,
      }}
      isRowSelectable={false}
      className="p-2"
    />
  );
};

export default DenominatorsTable;
