import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FaCheck } from "react-icons/fa";

const Form = ({
  formHeading,
  fields,
  title2,
  title,
  firstIcon,
  secondIcon,
  onClick,
  isShowCancel,
  isDisabled,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Initialize form data based on fields array
  const initialFormData = fields.reduce((acc, field) => {
    if (field.type === "number") {
      acc[field.name] = Number(field.value) || "";
    } else if (field.type === "radio" && field.value === "true") {
      acc[field.name] = true;
    } else if (field.type === "radio" && field.value === "false") {
      acc[field.name] = false;
    } else {
      acc[field.name] = field.value || "";
    }
    return acc;
  }, {});

  // Initialize errors object for each field
  const initialErrors = fields.reduce((acc, field) => {
    acc[field.name] = "";
    return acc;
  }, {});

  // State for form data and errors
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialErrors);

  // Handle input change
  const handleChange = (event) => {
    const { name, type, value, checked } = event.target;

    let newFormData = { ...formData };
    let newErrors = { ...errors };

    if (type === "checkbox") {
      if (checked) {
        newFormData[name] = [...(newFormData[name] || []), value];
      } else {
        newFormData[name] = newFormData[name].filter((item) => item !== value);
      }
    } else if (type === "radio") {
      if (value === "true") {
        newFormData[name] = true;
      } else if (value === "false") {
        newFormData[name] = false;
      } else {
        newFormData[name] = value;
      }
    } else if (type === "number") {
      newFormData[name] = type === "number" ? Number(value) : value;
    } else {
      newFormData[name] = value;
    }

    // Validate field based on type and update errors
    const field = fields.find((f) => f.name === name);
    if (field) {
      if (!newFormData[name] && newFormData[name] !== false) {
        // Ensure that false does not trigger an error
        newErrors[name] = "This field is required";
      } else if (field.type === "number") {
        const numericValue = value.trim();
        const numericLength = numericValue.length;

        if (numericLength < field.minLength) {
          newErrors[name] = `value must be at least ${field.minLength}`;
        } else if (numericLength > field.maxLength) {
          newErrors[name] = `value must be no more than ${field.maxLength}`;
        } else {
          newErrors[name] = "";
        }
      } else {
        newErrors[name] = "";
      }
    }
    setFormData(newFormData);
    setErrors(newErrors);
  };

  // Validate all fields before form submission
  const validate = () => {
    let tempErrors = {};
    fields.forEach((field) => {
      if (field.type === "radio") {
        if (formData[field.name] === undefined || formData[field.name] === "") {
          tempErrors[field.name] = `This field is required`;
        }
      } else if (!formData[field.name] && formData[field.name] !== false) {
        // Ensure that false does not trigger an error
        tempErrors[field.name] = `This field is required`;
      }
    });
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      onClick(formData);
      // Here you would typically handle form submission logic
      // setFormData(initialFormData);
    }
  };

  const resetFormValue = () => {
    setFormData(initialFormData);
  };

  const renderFields = (formFields, formData, errors) => {
    return formFields.map((field, i) => {
      const isVisible = field.showIf ? field.showIf(formData) : true;

      if (!isVisible) {
        return null;
      }

      return (
        <Grid key={i} item xs={12} sm={12} md={field?.size} lg={field?.size}>
          {field?.heading && (
            <div className="my-2">
              <p className="text-secondary">{field?.heading}</p>
            </div>
          )}
          {field?.subHeading && (
            <div className="my-2">
              <p className="text-secondary">{field?.subHeading}</p>
            </div>
          )}
          <div className={`${errors[field.name] ? "errorBack" : "noerror"}`}>
            <label className="classForText py-1" htmlFor={field.id}>
              <span className="colorApplyForStar">*</span> {field.label}
            </label>
            {field.type === "date" && (
              <>
                <input
                  size="small"
                  type="date"
                  id={field.id}
                  name={field.name}
                  onChange={handleChange}
                  className="form-control adjustTextSize text-dark"
                  placeholder={field.placeholder}
                  value={formData[field.name]}
                />
                {errors[field.name] && (
                  <h6 className=" py-2 errormsg">{errors[field.name]}</h6>
                )}
              </>
            )}
            {field.type === "text" && (
              <>
                <input
                  type={field?.type === "text" ? "text" : "email"}
                  id={field.id}
                  name={field.name}
                  onChange={handleChange}
                  className="form-control adjustTextSize text-dark rounded"
                  placeholder={field.placeholder}
                  value={formData[field.name]}
                />
                {errors[field.name] && (
                  <h6 className="py-2 errormsg">{errors[field.name]}</h6>
                )}
              </>
            )}
            {field.type === "number" && (
              <>
                <input
                  type="number"
                  id={field.id}
                  name={field.name}
                  onChange={handleChange}
                  className="form-control adjustTextSize text-dark rounded"
                  placeholder={field.placeholder}
                  value={formData[field.name]}
                />
                {errors[field.name] && (
                  <h6 className="py-2 errormsg">{errors[field.name]}</h6>
                )}
              </>
            )}
            {field.type === "radio" && (
              <>
                {field.options.map((option) => (
                  <div key={option.value} className="form-check">
                    <input
                      className="form-check-input custom-radio"
                      type="radio"
                      name={field.name}
                      id={`${field.name}-${option.value}`}
                      value={option.value}
                      checked={
                        formData[field.name] === option.value ||
                        formData[field.name] ===
                          (option.value === "true"
                            ? true
                            : option.value === "false"
                            ? false
                            : option.value)
                      }
                      onChange={handleChange}
                    />
                    <label
                      className="adjustTextSize mt-1 adjustSpace form-check-label ml-3"
                      htmlFor={`${field.name}-${option.value}`}
                    >
                      {option.label}
                    </label>
                  </div>
                ))}
                {errors[field.name] && (
                  <h6 className="py-2 errormsg radioError">
                    {errors[field.name]}
                  </h6>
                )}
              </>
            )}
            {field.type === "radio" &&
              formData[field.name] === "Others" &&
              field.subFields &&
              renderFields(field.subFields, formData, errors)}
            {field.type === "checkbox" && (
              <>
                {field.options.map((option) => (
                  <div key={option.value} className="form-check">
                    <input
                      className="form-check-input custom-checkbox"
                      type="checkbox"
                      name={field.name}
                      id={option.value}
                      value={option.value}
                      checked={formData[field.name][option.value]}
                      onChange={handleChange}
                    />
                    <label
                      className="adjustTextSize mt-1 adjustSpace form-check-label"
                      htmlFor={option.value}
                    >
                      {option.label}
                    </label>
                  </div>
                ))}
                {errors[field.name] && (
                  <h6 className="py-2 errormsg radioError">
                    {errors[field.name]}
                  </h6>
                )}
              </>
            )}
            {field.type === "checkbox" &&
              formData[field.name]?.includes("Others") &&
              field.subFields &&
              renderFields(field.subFields, formData, errors)}
            {field.type === "select" && (
              <>
                <select
                  id={field.id}
                  name={field.name}
                  onChange={handleChange}
                  className="form-control adjustTextSize text-dark rounded"
                  value={formData[field.name]}
                >
                  <option value="">Select an option</option>
                  {field.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {errors[field.name] && (
                  <h6 className="py-2 errormsg">{errors[field.name]}</h6>
                )}
              </>
            )}
            {((field.name === "designation" &&
              formData.designation === "other") ||
              (field.name === "testScreenForAnemia" &&
                formData.testScreenForAnemia === "other") ||
              (field.name === "AlbendazoleReceive" &&
                formData.AlbendazoleReceive === "other") ||
              (field?.name === "AMBReport" && formData.AMBReport === false)) &&
              field.subField &&
              field.subField.map((subField, index) => (
                <div key={index}>
                  <label className="classForText" htmlFor={subField.id}>
                    <span className="colorApplyForStar">*</span>{" "}
                    {subField.label}
                  </label>
                  <input
                    type="text"
                    id={subField.id}
                    name={subField.name}
                    onChange={handleChange}
                    className="form-control adjustTextSize text-dark rounded"
                    placeholder={subField.placeholder}
                    value={formData[subField.name] || ""}
                  />
                  {errors[subField.name] && (
                    <span className=" font-weight-bold mt-1">
                      {errors[subField.name]}
                    </span>
                  )}
                </div>
              ))}
            {((field.name === "last3Months" && formData.last3Months === true) ||
              (field.name === "last6Months" && formData.last6Months === true) ||
              (field.name === "next3Months" && formData.next3Months === true) ||
              (field.name === "next6Months" &&
                formData.next6Months === true)) &&
              field.IFAtype && (
                <div className="mt-3 ml-2">
                  <label className="classForText">{field.IFAtype.label}</label>
                  {field.IFAtype.options.map((option) => (
                    <div key={option.value} className="form-check">
                      <input
                        className="form-check-input custom-checkbox"
                        type="checkbox"
                        name={field.IFAtype.name}
                        id={option.value}
                        value={option.value}
                        checked={formData[field.IFAtype.name]?.includes(
                          option.value
                        )}
                        onChange={handleChange}
                      />
                      <label
                        className="adjustTextSize mt-1 adjustSpace form-check-label"
                        htmlFor={option.value}
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              )}
          </div>
        </Grid>
      );
    });
  };

  return (
    <>
      <div className="mt-3">
        <div className="row justify-content-center">
          <div className="border border-5 rounded border-light-3 py-4 px-4 d-flex flex-column align-items-center justify-content-center col-12 col-md-6 col-lg-6">
            <h2
              className={`text-center colorApply ${
                isSmallScreen ? "h4" : "h3"
              }`}
            >
              {formHeading}
            </h2>
            <Grid container spacing={2} marginTop={"8px"}>
              {renderFields(fields, formData, errors)}
            </Grid>

            <Box
              className="py-4"
              display={"flex"}
              gap={2}
              justifyContent={"center"}
              mt={2}
            >
              {isShowCancel && (
                <Button
                  variant="outlined"
                  sx={{
                    color: "#333333",
                    borderColor: "#cccccc",
                    ":hover": {
                      backgroundColor: "#cccccc",
                      borderColor: "#cccccc",
                    },
                    width: "150px",
                    height: "50px",
                    fontSize: "13px",
                  }}
                  gap={4}
                  size="large"
                >
                  {secondIcon && secondIcon} {title2}
                </Button>
              )}
              <Button
                type="reset"
                variant="outlined"
                color="primary"
                size="large"
                fullWidth={false}
                disabled={isDisabled}
                onClick={resetFormValue}
              >
                Reset
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                startIcon={<FaCheck />}
                fullWidth={false}
                disabled={isDisabled}
                onClick={handleSubmit}
              >
                {title}
              </Button>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
