import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

import App from "./App";
import {
  AMBRanking,
  About,
  AdminPanel,
  Beneficiaries,
  ChecklistBeneficiary,
  ChecklistDistrict,
  ContactUs,
  CountAdmin,
  DeleteExcelUploads,
  DrugRequirements,
  ErrorPage,
  ForgotPassword,
  IFAStock,
  KPI,
  Login,
  Register,
  ResourceAdmin,
  ResourcePage,
  SchoolForm,
  StrategyPage,
  UploadDenominators,
  UploadNumerators,
} from "./pages";

import Checklist from "pages/checklist/ChecklistState";
import BeneficiaryService from "pages/connect/BeneficiaryService";
import DenominatorsForgotPassword from "pages/reports/denominators/DenominatorsForgotPassword";
import DenominatorsHome from "pages/reports/denominators/DenominatorsHome";
import DenominatorsLogin from "pages/reports/denominators/DenominatorsLogin";
import DenominatorsRegister from "pages/reports/denominators/DenominatorsRegister";
import IndiaSituation from "pages/situation/IndiaSituation";
import StateSituation from "pages/situation/StateSituation";
import WorldSituation from "pages/situation/WorldSituation";

import DenominatorsLayout from "containers/DenominatorsLayout";
import MonitoringNDD from "pages/ndd-checklist/MonitoringNDD";
import PostNDD from "pages/ndd-checklist/PostNDD";
import PreNDD from "pages/ndd-checklist/PreNDD";
import { AdminLayout, HomeLayout } from "./containers/Layout";
import AuthProvider, { useAuth } from "./context/AuthContext";

const parseJwt = (token) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const rawBytes = new TextDecoder().decode(
      Uint8Array.from(atob(base64), (c) => c.charCodeAt(0))
    );
    return JSON.parse(rawBytes);
  } catch (e) {
    return null;
  }
};

function AppRouter() {
  const { token, removeToken, stateToken } = useAuth();

  useEffect(() => {
    const handleLogout = () => {
      removeToken();
    };

    if (token) {
      const decodedJwt = parseJwt(token);

      if (decodedJwt?.exp * 1000 < Date.now()) {
        handleLogout();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const ProtectedRoute = ({ children }) => {
    if (!token) {
      return <Navigate to="/auth/login" replace={true} />;
    }

    return children;
  };

  const StateUserRoute = ({ children }) => {
    if (!stateToken && stateToken !== "") {
      return <Navigate to="/reports/denominators/login" replace={true} />;
    }

    return children;
  };

  const routes = [
    {
      path: "/",
      element: <HomeLayout />,
      children: [
        { index: true, element: <App /> },
        { path: "checklist/state", element: <Checklist /> },
        {
          path: "programme",
          children: [
            { path: "about", element: <About /> },
            { path: "6x6x6-strategy", element: <StrategyPage /> },
            { path: "beneficiaries", element: <Beneficiaries /> },
          ],
        },
        {
          path: "anemia-situation",
          children: [
            { path: "global", element: <WorldSituation /> },
            { path: "india", element: <IndiaSituation /> },
            { path: "state", element: <StateSituation /> },
          ],
        },
        {
          path: "reports",
          children: [
            { path: "key-performance-indicators", element: <KPI /> },
            { path: "ranking", element: <AMBRanking /> },
            { path: "stock", element: <IFAStock /> },
            {
              path: "denominators",
              children: [
                { path: "login", element: <DenominatorsLogin /> },
                { path: "get-credentials", element: <DenominatorsRegister /> },
                {
                  path: "forgot-password",
                  element: <DenominatorsForgotPassword />,
                },
              ],
            },
          ],
        },
        { path: "resources/home", element: <ResourcePage /> },
        { path: "contact/home", element: <ContactUs /> },
        {
          path: "checklist",
          children: [
            { path: "school", element: <SchoolForm /> },
            { path: "serviceDelivery", element: <BeneficiaryService /> },
            { path: "beneficiary", element: <ChecklistBeneficiary /> },
            { path: "district", element: <ChecklistDistrict /> },
          ],
        },
        {
          path: "ndd",
          children: [
            { path: "monitoring", element: <MonitoringNDD /> },
            { path: "post", element: <PostNDD /> },
            { path: "pre", element: <PreNDD /> },
          ],
        },
        {
          path: "auth",
          element: <HomeLayout />,
          children: [
            { path: "login", element: <Login /> },
            { path: "register", element: <Register /> },
            { path: "forgotPassword", element: <ForgotPassword /> },
          ],
        },
        { path: "*", element: <ErrorPage /> },
      ],
    },
    {
      path: "reports/denominators/home",
      element: (
        <StateUserRoute>
          <DenominatorsLayout>
            <DenominatorsHome />
          </DenominatorsLayout>
        </StateUserRoute>
      ),
    },
    {
      path: "/admin",
      element: (
        <ProtectedRoute>
          <AdminLayout />
        </ProtectedRoute>
      ),
      children: [
        { path: "home", element: <AdminPanel /> },
        { path: "upload-resources", element: <ResourceAdmin /> },
        { path: "upload-drug-requirements", element: <DrugRequirements /> },
        { path: "upload-denominators", element: <UploadDenominators /> },
        { path: "upload-numerator", element: <UploadNumerators /> },
        { path: "delete-uploads", element: <DeleteExcelUploads /> },
        { path: "count-admin", element: <CountAdmin /> },
      ],
    },
  ];

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  </React.StrictMode>
);
