import { useEffect, useState } from "react";

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DoneIcon from '@mui/icons-material/Done';

import "./ContactUs.css";
import API from "services/api";
import Form from '../../components/DefaultForm/Form';
import { fieldsForForm } from "./dummyData";
import Loader from "utils/Loader";

const BeneficiaryService = () => {
  const [loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {
    async function CountStatic() {
      try {
        await API.getCountStatic();
      } catch (error) { }
    }
    CountStatic();
  }, []);

  const handleData = async (formData) => {
    const payload = {
      date: formData?.date,
      state: formData?.state,
      district: formData?.district,
      block: formData?.block,
      chc: formData?.chc_phc,
      subCenter: formData?.subCenterName,
      vhnd: formData?.VHND_AWC,
      flw: formData?.nameOfFlw,
      designation: formData?.designation,
      mobileNumber: formData?.mobileNumber,
      supervisorName: formData?.supervisor_monitor,
      organisation: formData?.Organization,
      supervisorDesignation: formData?.supervisorDesignation,
      supervisorMobile: formData?.supervisorMobile,
      receivedTraining: formData?.trainingAMB,
      totalPopulationCatered: formData?.totalPopulation,
      womenReproductiveAge: formData?.reproductiveAge,
      pregnantWomen: formData?.totalPregnanatWomen,
      lactatingWomen: formData?.totalLactatingWomen,
      children6_59: formData?.totalChildrenMonth,
      children5_9: formData?.totalChildrenYear,
      adols10_19: formData?.totalAdolescentsYear,
      anemiaScreeningMethod: formData?.testScreenForAnemia,
      digitalHemoglobinometers: formData?.digitalHemoglobinometers,
      consumables: formData?.consumables,
      beneWomenReproductiveAge: formData?.reproductiveAge4,
      benePregnantWomen: formData?.pregnantWomen4,
      beneLactatingWomen: formData?.totalLactatingWomen4,
      beneChildren6_59: formData?.totalChildrenMonth4,
      beneChildren5_9: formData?.totalChildrenYear4,
      beneAdols10_19: formData?.totalAdolescentsYear4,
      provideAlbendazole: formData?.provideAlbendazole?.join(', '),
      pregnantWomenRegistered: formData?.currentlyRegister,
      pregnantWomenAnemic: formData?.screenedAnemic,
      pregnantWomenTreatment: formData?.onTreatment,
      pregnantWomenReferred: formData?.anemicReferred,
      pregnantWomenLineListing: formData?.anemicPW,
      iecMaterial: formData?.VHND_AWC,
      iecMethod: formData?.IEC?.join(', '),
      flwAdvice: formData?.ANCSession,
      t3CampOrganised: formData?.T3Camp,
      ifaCollectionLocation: formData?.AlbendazoleReceive,
      freqReceiptIFA: formData?.IFA,
      freqReceiptAlbendazole: formData?.albendazole,
      stockOutIfa3Months: formData?.last3Months,
      stockOutIfa3MonthsList: formData?.stockOutIfa3MonthsList?.join(', '),
      stockOutIfa6Months: formData?.last6Months,
      stockOutIfa6MonthsList: formData?.stockOutIfa6MonthsList?.join(', '),
      enoughStockIfa3Months: formData?.next3Months,
      enoughStockIfa3MonthsList: formData?.enoughStockIfa3MonthsList?.join(', '),
      enoughStockIfa6Months: formData?.next6Months,
      enoughStockIfa6MonthsList: formData?.enoughStockIfa6MonthsList?.join(', '),
      ifaWithinExpiry: formData?.expiryDate,
      receivedHmisFormat: formData?.HMISFormat,
      submittedAmbReport: formData?.AMBReport,
      submittedAmbReportReason: formData?.submittedAmbReportReason,
      dosageReproductiveAge: formData?.reproductiveAge8,
      dosagePregnantWomen: formData?.totalPregnanatWomen8,
      dosageLactatingWomen: formData?.totalLactatingWomen8,
      dosageChild659: formData?.totalChildrenMonth8,
      dosageChild59: formData?.dosageChild59,
      dosageAdols_1059: formData?.totalAdolescentsYear8
    }
    try {
      setLoadingStatus(true);
      const response = await API.postSeviceDeliveryChecklist(payload);
      if (response.success) {

      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingStatus(false);
    }
  }

  return (
    <>
      <div className="contact-main-container container-fluid">
        {
          loadingStatus ? (<Loader loading={loadingStatus} />) : (
            <Form
              formHeading="AMB Checklist_Service Delivery Point"
              fields={fieldsForForm}
              title={"Submit"}
              title2={"Save Draft"}
              secondIcon={<EditOutlinedIcon fontSize="8px" />}
              firstIcon={<DoneIcon fontSize="8px" />}
              onClick={(formData) => handleData(formData)}
              isShowCancel={false}
            />
          )
        }
      </div>
    </>
  );
};

export default BeneficiaryService;
