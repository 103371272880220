import { useState } from "react";

import DoneIcon from "@mui/icons-material/Done";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import API from "services/api";
import Loader from "utils/Loader";
import SnackBar from "utils/SnackBar";
import Form from "../../components/DefaultForm/Form";
import { fieldsForForm } from "./stateForm";

const ChecklistState = () => {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    text: "",
    type: "",
  });

  const handleData = async (formData) => {
    const payload = {
      ...formData,
    };
    try {
      setLoadingStatus(true);
      const response = await API.submitChecklistState(payload);
      if (response.success) {
        setShowSnackBar(true);
        setSnackBarMessage({
          text: "Form submitted successfully!",
          type: "success",
        });
      }
    } catch (error) {
      setShowSnackBar(true);
      setSnackBarMessage({
        text: "Failed to submit form. Please try again later.",
        type: "error",
      });
    } finally {
      setLoadingStatus(false);
    }
  };

  const handleCloseSnackBar = () => {
    setShowSnackBar(false);
  };

  return (
    <>
      <SnackBar
        message={snackBarMessage.text}
        open={showSnackBar}
        handleClose={handleCloseSnackBar}
        type={snackBarMessage.type}
      />
      <div className="contact-main-container container-fluid">
        {loadingStatus && <Loader loading={loadingStatus} />}
        <Form
          formHeading="AMB Checklist_State"
          fields={fieldsForForm}
          title={"Submit"}
          title2={"Save Draft"}
          secondIcon={<EditOutlinedIcon fontSize="8px" />}
          firstIcon={<DoneIcon fontSize="8px" />}
          onClick={(formData) => handleData(formData)}
          isShowCancel={false}
        />
      </div>
    </>
  );
};

export default ChecklistState;
