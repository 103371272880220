import React, { useEffect, useState } from "react";
import "./Denominators.css";

import DenominatorsForm from "components/reports/denominators/DenominatorsForm";
import DenominatorsTable from "components/reports/denominators/DenominatorsTable";
import API from "services/api";
import { useAuth } from "context/AuthContext";
import SnackBar from "utils/SnackBar";

function Denominators() {
  const [selectedYear, setSelectedYear] = useState("");

  const [stateList, setStateList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");

  const [selectedType, setSelectedType] = useState("1");

  const [downloadURL, setDownloadURL] = useState();
  const [tableData, setTableData] = useState([]);

  const { stateToken } = useAuth();

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    type: "",
  });

  const handleClose = () => {
    setShow(false);
  };

  const handleLabelYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  useEffect(() => {
    const getStateList = async () => {
      try {
        const states = await API.getAllStates(selectedYear);
        setStateList(states);
      } catch (error) {}
    };
    if (stateToken === "INDIA") {
      getStateList();
    }
  }, [selectedYear, stateToken]);

  const handleStateChange = (e) => {
    setSelectedLocation(e.target.value);
  };

  const handleTypeChange = (event) => {
    const selectedType = event.target.value;
    setSelectedType(selectedType);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if ((!selectedYear || !selectedType) && !e.target.value) {
      setShow(true);
      setMessage({
        text: "Please select both Year and Type.",
        type: "warning",
      });
      return;
    }

    try {
      setMessage(null);
      const response = await API.getDenominatorsData(
        selectedLocation ? selectedLocation : stateToken,
        selectedYear,
        selectedType
      );

      if (response.statusCode === 200) {
        setDownloadURL(response.message);
        setTableData(response.data);
      }
    } catch (err) {
      setShow(true);
      setMessage({
        text: "Error fetching denominators data. Please try again.",
        type: "error",
      });
    }
  };

  const columnDefsTB = [
    {
      field: "location",
      headerName: "Location",
      width: 300,
      headerClassName: "table-header",
      renderCell: (params) => {
        return (
          <div className={params.field === "location" && "first-column"}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "months6_59",
      headerName: "Children 6-59 Months",
      width: 200,
      headerClassName: "table-header",
    },
    {
      field: "months12_59",
      headerName: "Children 12-59 Months",
      width: 200,
      headerClassName: "table-header",
    },
    {
      field: "years5_9",
      headerName: "Children 5-9 Years",
      width: 200,
      headerClassName: "table-header",
    },
    {
      field: "school10_19",
      headerName: "School 10-19 Years",
      width: 200,
      headerClassName: "table-header",
    },
    {
      field: "schoolGirl10_19",
      headerName: "School Girl 10-19 years",
      width: 200,
      headerClassName: "table-header",
    },
    {
      field: "schoolBoy10_19",
      headerName: "School Boy 10-19 years",
      width: 200,
      headerClassName: "table-header",
    },
    {
      field: "oosAdolsGirls10_19",
      headerName: "Adolescents 10-19 years",
      width: 200,
      headerClassName: "table-header",
    },
    {
      field: "estPregnentWomen",
      headerName: "Pregnant Women",
      width: 200,
      headerClassName: "table-header",
    },
    {
      field: "lactatingMother",
      headerName: "Lactating Mother",
      width: 200,
      headerClassName: "table-header",
    },
    {
      field: "totalAdols",
      headerName: "Total Adolescents",
      width: 200,
      headerClassName: "table-header",
    },
  ];

  const columnDefsDR = [
    {
      field: "location",
      headerName: "Location",
      width: 300,
      headerClassName: "table-header",
      renderCell: (params) => {
        return (
          <div className={params.field === "location" && "first-column"}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "ifaPink",
      headerName: "IFA Pink",
      width: 200,
      headerClassName: "table-header",
    },
    {
      field: "syrupPaediatric",
      headerName: "Syrup Paediatric",
      width: 200,
      headerClassName: "table-header",
    },
    {
      field: "ifaRed",
      headerName: "IFA Red",
      width: 200,
      headerClassName: "table-header",
    },
    {
      field: "ifaBlue",
      headerName: "IFA Blue",
      width: 200,
      headerClassName: "table-header",
    },
    {
      field: "albendazole",
      headerName: "Albendazole",
      width: 200,
      headerClassName: "table-header",
    },
  ];

  return (
    <>
      <SnackBar
        message={message?.text}
        type={message?.type}
        open={show}
        handleClose={handleClose}
      />

      <div className="denominators-container">
        <div className="container mx-auto content-denominators">
          <DenominatorsForm
            onSubmit={handleSubmit}
            onYearLabelChange={handleLabelYearChange}
            selectedYear={selectedYear}
            stateList={stateList}
            selectedLocation={selectedLocation}
            onLocationChange={handleStateChange}
            selectedType={selectedType}
            onTypeChange={handleTypeChange}
          />

          <div className="row-denominators">
            <div className="row-text">
              “The denominators are the target beneficiary numbers across each
              age group of Anemia Mukt Bharat. These denominators are
              estimations (based on Census 2011 data) and reported programme
              targets. The denominators for each of these target groups are
              fixed for the FY and no edits of the data are allowed in back
              end.”
            </div>
          </div>

          <DenominatorsTable
            downloadURL={downloadURL}
            tableData={tableData}
            columnDefs={selectedType === "1" ? columnDefsTB : columnDefsDR}
            selectedLocation={selectedLocation}
            selectedYear={selectedYear}
          />
        </div>
      </div>
    </>
  );
}

export default Denominators;
