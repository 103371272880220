import React from "react";
import styles from "./ChecklistComponent.module.css";
import { TextField } from "@mui/material";

const ChecklistCodeInput = ({ handleChange, value }) => {
  return (
    <div className={styles.formGroup}>
      <label className={styles.label} htmlFor="date">
        Code <span className={styles.requiredStar}>*</span>
      </label>
      <TextField
        placeholder="Enter checklist code to access"
        size="small"
        className={styles.formInput}
        type="text"
        name="code-checklist"
        id="code-checklist"
        value={value}
        onChange={(e) => handleChange(e.target.value)}
      />
    </div>
  );
};

export default ChecklistCodeInput;
